import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


const Footer = () => (
<footer>
  <section className="section">
        <div className="container">
                <div className="columns is-vcentered">
                        <div className="column">
                        <p>© {new Date().getFullYear()}, Built by          {` `}
          <a className="has-text-grey" href="https://olto.com.au">Olto. | Web Development and SEO Adelaide.</a></p>
                        </div>
                        <div className="column has-text-centered">
                        <StaticImage src="../images/CruiserCamperslogolarge.png" width={180} />   
                        </div>
                        <div className="column has-text-centered">
                        <span class="icon-text py-2">
              <span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>
              <span className="is-marker">Experience</span>
              <span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>
              <span className="is-marker">Quality</span>
              <span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>
              <span className="is-marker">Price</span>

            </span>
                        </div>
                        </div>
        </div>

           </section>
        </footer>
)


export default Footer