import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = ({ siteTitle }) => {
  const [ismobtrue, setmobmenu] = useState(false);

  const [isshown, setIsShown] = useState(false);

  return (
  <header>

  
    <nav className="navbar custom-navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <Link className="navbar-item" to="/">
      <StaticImage src="../images/campers-logo.png" className="header-logo-img" />

</Link>
<a role="button" className={ismobtrue ? 'navbar-burger is-active' : 'navbar-burger'} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={() => setmobmenu(!ismobtrue)}>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" className={ismobtrue ? 'navbar-menu is-active' : 'navbar-menu'}>
    
    <div className="navbar-end">
    <AnchorLink className="navbar-item" to="/#the-cruiser">
        The Cruiser
      </AnchorLink>

      <Link className="navbar-item" to="/#cruiser-gallery">
        Gallery
      </Link>


      <Link className="navbar-item" to="/#cruiser-difference">
        Our Difference
      </Link>

      <Link className="navbar-item" to="/cruiser-campers-blog">
        Blog
      </Link>

     
      <div className="navbar-item">
        <div className="buttons">
        <a  href="https://www.camplify.com.au/rv/campervan-rental-adelaide-sa-cruiser-camper/16304" target="_blank" className="button has-background-light" to="#cruiser-contact">
            <strong>Book The Cruiser</strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

  </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
